import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from '../../Component/Header/Header';
import { fetchGetAllProject } from '../../Redux/Projects/Projects';
import { AppDispatch } from '../../store';

type PropsRouterWithHeader = {
    Component: any;
}

const RouterWithHeader: React.FC<PropsRouterWithHeader> = ({ Component }) => {

    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/timeline' || location.pathname === '/')
            dispatch(fetchGetAllProject());
    }, [dispatch, location])

    return (
        <div>
            <Header />
            { Component }
        </div>
    )
}

export default RouterWithHeader;