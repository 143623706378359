import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { DataTextTransform } from '../../Component/Timeline/DataText';
// import MediaTransform from '../../Component/Timeline/Media';
import { GetProjectId, GetProjects, GetProjectsAuth, PostProject, UpdateProject } from '../../Request/Project';
import { DeleteDataRequest, Request } from '../../Request/Request';
import type { RootState } from './../../store';

interface Project {
    id_project: string;
    name: string;
    budget: number;
    benefits: string;
    context: string;
    goals: string;
    delivered_at: string;
    started_project: string;
    display_started_project: boolean;
    link: string;
    client: {
        id: string;
        name: string;
    }[];
    project_tag: {}[];
    project_type: {}[];
}

interface ProjectsState {
    projects: Project[] | [];
    projectsSave: Project[] | [];
    statusRequest: string;
    idsDelete: number[] | [];
    dataRequest: string | undefined;
    error: string | undefined;
    method: string | undefined,
    message: string | undefined,
    data: {
        id_project: string,
        name: string,
    } | undefined,
}

const initialState: ProjectsState = {
    projects: [],
    projectsSave: [],
    statusRequest: "loading",
    idsDelete: [],
    dataRequest: undefined,
    method: undefined,
    message: undefined,
    data: undefined,
    error: undefined
}

export const fetchProjectPost = createAsyncThunk('post/project/:id', async (data: any) => {
    const rep = await Request(PostProject(data));
    return { ...rep, name: data.name, id_project: rep.id_project };
})

export const fetchProjectUpdate = createAsyncThunk('update/project/:id', async (data: any) => {
    const rep = await Request(UpdateProject(data.id, data.form));
    return { ...rep, name: data.name, id_project: data.id };
})

export const fetchGetAllProjectAuth = createAsyncThunk('auth', async () => {
    const rep = await GetProjectsAuth().then((rep: any) => {
        return rep;
    }).catch((error: any) => {
        console.log(error);
    })
    const project: [] = await new Promise(async (resolve) => {
        await Promise.all(rep.map(async (element: any) => {
            return await GetProjectId(element.id_project)
                .then((rep: any) => {
                    return rep;
                })
                .catch((error: any) => {
                    console.log(error);
                })
        })).then((rep: any) => {
            resolve(rep);
        })
    })
    return project
})

export const fetchGetAllProject = createAsyncThunk('', async () => {
    const rep = await GetProjects().then((rep: any) => {
        return rep;
    }).catch((error: any) => {
        console.log(error);
    });
    return rep;
})

export const fetchProjectDelete = createAsyncThunk('/project/:id', async (data: {id: string, path: string, name: string}) => {
    const rep = await Request(DeleteDataRequest(data.id, data.path));
    return { ...rep.dataRequest, name: data.name, id_project: data.id };
})

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addIdsDelete: (state, action) => {
            state.idsDelete = [...state.idsDelete, action.payload];
        },
        removeIdsDelete: (state, action) => {
            state.idsDelete = state.idsDelete.filter((element: any) => element.id_project !== action.payload.id_project);
        },
        addAllIdsDelete: (state, action) => {
            state.idsDelete = action.payload.map((element: any) => element);
        },
        removeAllIdsDelete: (state) => {
            state.idsDelete = [];
        },
        initialOrder: (state) => {
            state.projects = state.projectsSave.filter((elementSave: any) => {
                return state.projects.find((element: any) => elementSave.id_project === element.id_project)
            })
        },
        orderAcs: (state, action) => {
            state.projects = state.projects.sort((a: any, b: any) => {
                if (Array.isArray(a[action.payload])) {
                    return a.client[0].name.toUpperCase() > b.client[0].name.toUpperCase() ? 1 : -1;
                }
                if (a[action.payload] instanceof String || typeof a[action.payload] === 'string') {
                    return a[action.payload].toUpperCase() > b[action.payload].toUpperCase() ? 1 : -1;
                }
                return a[action.payload] > b[action.payload] ? 1 : -1;
            })
        },
        orderDesc: (state, action) => {
            state.projects = state.projects.sort((a: any, b: any) => {
                if (Array.isArray(a[action.payload])) {
                    return a.client[0].name.toUpperCase() < b.client[0].name.toUpperCase() ? 1 : -1;
                }
                if (a[action.payload] instanceof String || typeof a[action.payload] === 'string') {
                    return a[action.payload].toUpperCase() < b[action.payload].toUpperCase() ? 1 : -1;
                }
                return a[action.payload] < b[action.payload] ? 1 : -1;
            })
        },
        filterProjects: (state, action) => {
            const { delivered_at, client, project_tag, project_type, projects } = action.payload;
            if (delivered_at.length === 0 && client.length === 0 && project_tag.length === 0 && project_type.length === 0) {
                state.projects = projects.map((element: any) => element);
            } else {
                const test = projects.filter((elementProject: any) => {
                    if (client.length !== 0) {
                        return elementProject.client.find((elementProjectClient: any) => {
                            return client.some((element: any) => {
                                return element.label === elementProjectClient.id_client
                            })
                        })
                    }
                    return elementProject;
                }).filter((elementProject: any) => {
                    if (project_type.length !== 0) {
                        return elementProject.project_type.find((elementProjectType: any) => {
                            return project_type.some((element: any) => {
                                return element.label === elementProjectType.id_project_type
                            })
                        })
                    }
                    return elementProject
                }).filter((elementProject: any) => {
                    if (project_tag.length !== 0) {
                        return elementProject.project_tag.find((elementProjectTag: any) => {
                            return project_tag.some((element: any) => {
                                return element.label === elementProjectTag.id_project_tag
                            })
                        })
                    }
                    return elementProject
                }).filter((elementProject: any) => {
                    if (delivered_at.length !== 0) {
                        return delivered_at.some((element: any) => {
                            return element.value === elementProject.delivered_at.split('-')[0]
                        })
                    }
                    return elementProject
                })
                state.projects = test.map((element: any) => element)
            }
        },
        filterProjectsByClient: (state, action) => {
            const {clients} = action.payload;
            const actifClient = clients.filter((element: any) => element.actif);
            const projects = state.projectsSave;
            if (actifClient.length === 0) {
                state.projects = projects.map((element: any) => element);
            } else {
                const test: any = projects.filter((elementProject: any) => {
                    if (actifClient.length !== 0) {
                        return elementProject.client.find((elementProjectClient: any) => {
                            return actifClient.some((element: any) => {
                                return element.name === elementProjectClient.name
                            })
                        })
                    }
                    return elementProject;
                })
                state.projects = test.map((element: any) => element)
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchGetAllProjectAuth.pending, (state, action) => {
                state.statusRequest = 'loading'
                state.projects = [];
            })
            .addCase(fetchGetAllProjectAuth.fulfilled, (state, action) => {
                state.statusRequest = 'succeeded'
                state.projects = action.payload.map((element: any) => element);
                state.projectsSave = action.payload.map((element: any) => element);
            })
            .addCase(fetchGetAllProjectAuth.rejected, (state, action) => {
                state.statusRequest = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchGetAllProject.pending, (state, action) => {
                state.statusRequest = 'loading'
                state.projects = [];
            })
            .addCase(fetchGetAllProject.fulfilled, (state, action) => {
                state.statusRequest = 'succeeded'
                state.projects = action.payload.map((element: any) => element);
                state.projectsSave = action.payload.map((element: any) => element);
            })
            .addCase(fetchGetAllProject.rejected, (state, action) => {
                state.statusRequest = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchProjectPost.pending, (state, action) => {
                state.method = 'post';
                state.statusRequest = 'loading';
            })
            .addCase(fetchProjectPost.fulfilled, (state, action) => {
                return {
                    ...state,
                    method: 'post',
                    statusRequest: 'succeeded',
                    data: {
                        id_project: action.payload.id_project,
                        name: action.payload.name,
                    }
                }
            })
            .addCase(fetchProjectPost.rejected, (state, action: any) => {
                return {
                    ...state,
                    statusRequest: 'failed',
                    method: 'post',
                    error: action.error.message,
                    data: {
                        id_project: action.data?.id_project || '',
                        name: action.payload.name,
                    }
                }
            })
            .addCase(fetchProjectUpdate.pending, (state, action) => {
                state.method = 'update';
                state.statusRequest = 'loading';
            })
            .addCase(fetchProjectUpdate.fulfilled, (state, action) => {
                return {
                    ...state,
                    method: 'update',
                    statusRequest: 'succeeded',
                    data: {
                        id_project: action.payload.id_project,
                        name: action.payload.name,
                    }
                }
            })
            .addCase(fetchProjectUpdate.rejected, (state, action: any) => {
                return {
                    ...state,
                    statusRequest: 'failed',
                    method: 'update',
                    error: action.error.message,
                    data: {
                        id_project: action.data?.id_project || '',
                        name: action.meta.arg.name,
                    }
                }
            })
            .addCase(fetchProjectDelete.fulfilled, (state, action) => {
                return {
                    ...state,
                    method: 'delete',
                    statusRequest: 'succeeded',
                    data: {
                        id_project: action.payload.id_project,
                        name: action.payload.name,
                    }
                }
            })
            .addCase(fetchProjectDelete.rejected, (state, action) => {
                state.statusRequest = 'failed';
                state.error = action.error.message;
            })
    }
})

export const { filterProjectsByClient, filterProjects, addIdsDelete, removeIdsDelete, addAllIdsDelete, removeAllIdsDelete, initialOrder, orderAcs, orderDesc } = projectsSlice.actions

export const selectAllProjects = (state: RootState) => state.projects;

export const selectAllProjectsSave = (state: RootState) => state.projects.projectsSave;

export const selectAllProjectsEvent = (state: RootState, fullscreen: boolean) => {
    return {
        ...state.projects,
        projects: state.projects.projects.map((element) => {
            return {
                name: element.name,
                client: element.client.map((element: any) => {
                    return { name: element.name, main_color: element.main_color }
                }),
                project_type: element.project_type,
                project_tag: element.project_tag,
                display_started_project: element.display_started_project,
                start_date: {
                    year: fullscreen ? element.display_started_project ? element.started_project.split('-')[0] : element.delivered_at.split('-')[0] : element.delivered_at.split('-')[0],
                    month: fullscreen ? element.display_started_project ? element.started_project.split('-')[1] : element.delivered_at.split('-')[1] : element.delivered_at.split('-')[1],
                    day: fullscreen ? element.display_started_project ? element.started_project.split('-')[2].split('T')[0] : element.delivered_at.split('-')[2].split('T')[0] : element.delivered_at.split('-')[2].split('T')[0]
                },
                end_date: {
                    year: fullscreen ? element.display_started_project ? element.delivered_at.split('-')[0] : element.started_project.split('-')[0] : element.started_project.split('-')[0],
                    month: fullscreen ? element.display_started_project ? element.delivered_at.split('-')[1] : element.started_project.split('-')[1] : element.started_project.split('-')[1],
                    day: fullscreen ? element.display_started_project ? element.delivered_at.split('-')[2].split('T')[0] : element.started_project.split('-')[2].split('T')[0] : element.started_project.split('-')[2].split('T')[0]
                },
                unique_id: element.id_project.toString(),
                group: undefined,
                background: {},
            }
        }),
    }
}

export const selectIdsDelete = (state: RootState) => state.projects.idsDelete;

export const getProjectRedux = (state: RootState) => state.projects;

export default projectsSlice.reducer