import LacosteBackgroundLogin from "./../asset/images/background/ki-background.jpg";

export const TitleTimeLine = "Timeline";

export const COLOR = '#4309C6';

export const COLOR_HOVER = '#200067';

export const FILTRE_0 = 'Année'

export const FILTRE_1 = 'Département';

export const LABEL_FILTRE_1 = 'Département'

export const NUMBER_DATA_CLIENT = 'Nombre Département'

export const PROGRESS_TOP_1 = {
    title: 'TOP Département',
    subTitle: "Timeline_Home.Top_First.Title_Second",
}

export const CLIENT_SELECT_PROJECT = "Département"

export const INPUT_DATA_FILTRE_1 = {
    label: "Nom du département",
    placeholder: "Nom du département",
    name: "client"
}

export const FILTRE_2 = 'Service';

export const LABEL_FILTRE_2 = 'Service';

export const PROGRESS_TOP_2 = {
    title: 'TOP Service',
    subTitle: "Timeline_Home.Top_Last.Title_Second",
}

export const TYPE_SELECT_PROJECT = 'Service'

export const FILTRE_3 = 'Techno';

export const LABEL_FILTRE_3 = 'Techno';

export const FILTRE_SELECT_PROJECT = 'Filtre supplémentaire';

export const BACKGROUND = LacosteBackgroundLogin;

export const OPEN_TIMELINE = true;

// export const BASE_URL = '/kianalytics';

export const BASE_URL = '';

export const FILTER_1_DELETE_TITLE = "d'un département"

export const FILTER_1_DELETE_TITLES = "département"

export const FILTER_1_DELETE_TEXT = "le Département";

export const FILTER_1_DELETE_TEXTS = "les Départements";

export const FILTER_2_DELETE_TITLE = "d'un service";

export const FILTER_2_DELETE_TITLES = "service"

export const FILTER_2_DELETE_TEXT = "le service";

export const FILTER_2_DELETE_TEXTS = "les services";

export const FILTER_3_DELETE_TITLE = "d'une techno";

export const FILTER_3_DELETE_TITLES = "techno";

export const FILTER_3_DELETE_TEXT = "la techno";

export const FILTER_3_DELETE_TEXTS = "les techno";
