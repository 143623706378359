import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { AccountRequest } from '../Request/Sign_in';
import { Request } from '../Request/Request';
import RouterWithHeader from './Component/RouterWithHeader';
import PrivateRoute from './Component/PrivateRoute';
import RouterIsLogin from './Component/RouterIsLogin';
import { BASE_URL } from '../Constant/Constant';

const OptionsManager = lazy(() => import('../Page/OptionsManager/OptionsManager'));
const UsersManager = lazy(() => import('../Page/UsersManager/UsersManager'));
// const Setup = lazy(() => import('../Page/Setup'));
const Smtp = lazy(() => import('../Page/Smtp/Smtp'));
const SignIn = lazy(() => import('../Page/SignIn'));
// const NotFound = lazy(() => import('../Page/NotFound'));
const ProjectForm = lazy(() => import('../Page/ProjectForm/ProjectForm'));
const ProjectsManager = lazy(() => import('../Page/ProjectsManager/ProjectsManager'));
const FirstConnection = lazy(() => import('../Page/FirstConnection'));

const RouterTimeLine: React.FC = () => {

    const [login, setLogin] = useState<{
        logged: boolean,
        role: string
    }>({
        logged: false,
        role: 'user'
    });
    const [request, setRequest] = useState<boolean>(false);

    useEffect(() => {
        const checkConnexion = async () => {
            await Request(AccountRequest()).then((rep: any) => {
                setLogin({
                    logged: true,
                    role: rep.dataRequest.admin_type.type,
                });
            }).catch((error: any) => {
                setLogin({
                    logged: false,
                    role: 'user'
                });
            });
            setRequest(true);
        }
        checkConnexion();
    }, [])

    if (request) {
        return (
            <Router basename={BASE_URL}>
                <Routes>
                    <Route path="/sign_in" element={
                        <Suspense fallback={<></>}>
                            <SignIn />
                        </Suspense>
                    } />
                    <Route path="/timeline" element={
                        <Suspense fallback={<></>}>
                            <RouterIsLogin login={login} />
                        </Suspense>
                    } />
                    <Route path={"/projects"}>
                        <Route path={"/projects"} element={
                            <Suspense fallback={<></>}>
                                <PrivateRoute path={"/projects"} login={login}>
                                    <RouterWithHeader Component={<ProjectForm />} />
                                </PrivateRoute>
                            </Suspense>
                        } />
                        <Route path={"/projects/:id"} element={
                            <Suspense fallback={<></>}>
                                <PrivateRoute path={"/projects/:id"} login={login}>
                                    <RouterWithHeader Component={<ProjectForm />} />
                                </PrivateRoute>
                            </Suspense>
                        } />
                    </Route>
                    <Route path="/manage_project" element={
                        <Suspense fallback={<></>}>
                            <PrivateRoute path='/manage_project' login={login}>
                                <RouterWithHeader Component={<ProjectsManager />} />
                            </PrivateRoute>
                        </Suspense>
                    }/>
                    <Route path='/manage_users' element={
                        <Suspense fallback={<></>}>
                            <PrivateRoute path='/manage_users' login={login}>
                                <RouterWithHeader Component={<UsersManager />} />
                            </PrivateRoute>
                        </Suspense>
                    }/>
                    <Route path="/first_connect/" element={<FirstConnection />} />
                    <Route path="/manage_options" element={
                        <Suspense fallback={<></>}>
                            <PrivateRoute path='/manage_options' restriction={true} login={login}>
                                <RouterWithHeader Component={<OptionsManager />}/>
                            </PrivateRoute>
                        </Suspense>
                    } />
                    <Route path="/setup" element={
                        <Suspense fallback={<></>}>
                            <PrivateRoute path='/setup' restriction={true} login={login}>
                                <RouterWithHeader Component={<Smtp />}/>
                            </PrivateRoute>
                        </Suspense>
                    } />
                    <Route path="/" element={<Navigate to="/timeline"/>}/>
                    <Route path="/*" element={<Navigate to='/404'/>}/>
                </Routes>
            </Router>
        )
    } else {
        return null
    }
}

export default RouterTimeLine;