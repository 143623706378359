import React, { useState, useEffect } from 'react';
import { rem } from 'polished';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import arrowColor from './../../asset/images/arrow-filtersColor.svg';
import { AccountRequest } from "./../../Request/Sign_in";
import { useNavigate } from 'react-router-dom';
import { BASE_URL, COLOR } from '../../Constant/Constant';
import { Request } from '../../Request/Request';
import { useTranslation } from 'react-i18next';

type PropsDropdownItem = {
    href: string;
    location: string;
    text: string;
}

const DropdownItem: React.FC<PropsDropdownItem> = ({href, location, text}) => {
    if (href !== location) {
        return (
            <>
                <Dropdown.Item href={href}>{text}</Dropdown.Item>
                <Dropdown.Divider />
            </>
        )        
    }
    return null
}


const DropdownParametre = styled(Dropdown) `
    margin-left: 10px;

    .btn {
        display: flex;
        height: 30px;
        color: ${COLOR};
        font: normal normal normal 12px/16px InterRegular;
    }

    .dropdown-toggle {
        color: ${COLOR};
    }

    & > .dropdown-menu {
        transform: translate3d(0px, 30px, 0px)!important;
        min-width: 100%;
        border-radius: 0px 0px 5px 5px !important;
    }

    & .dropdown-toggle::after {
        border: none;
        content: url(${arrowColor});
        color: ${COLOR};
        vertical-align: initial;
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
    }

    &.show > .dropdown-toggle::after,
    &.show > .dropdown-toggle::after {
        border: none;
        background-color: white;
        content: '';
        -webkit-mask: url(${arrowColor}) no-repeat center center;
        mask: url(${arrowColor}) no-repeat center center;
        vertical-align: initial;
        width: 20px;
        height: 20px;
        position: relative;
        top: -2px;
    }
    
    button {
        background-color: white;
        color: ${COLOR};
        border-radius: 5px;
        border: 1px solid ${COLOR};
    }

    & > button:hover, & > button:active, & > button:focus {
        background-color: white !important;
        box-shadow: none;
        border-color: ${COLOR} !important;
    }

    &.show > .btn-success.dropdown-toggle:focus, & > .btn-success:active:focus {
        box-shadow: none !important;
    }

    &.show > button:hover,
    &.show > .btn-success.dropdown-toggle {
        background-color: ${COLOR} !important;
        color: white;
        border-radius: 5px 5px 0px 0px;
        border-color: ${COLOR} !important;
    }

    .dropdown-item {
        font: normal normal normal 12px/21px InterRegular;
    }
`

const Link = styled.a `
    height: 30px;
    border: 1px solid ${COLOR};
    background: white;
    color: ${COLOR};
    text-decoration: none;
    border-radius: 5px;
    font: normal normal normal 12px/12px InterBold;
    padding: ${rem(9)};
    margin-left: ${rem(10)};

    :hover {
        background: ${COLOR};
        color: white;
    }
`

const Parameter: React.FC = () => {

    const { t } = useTranslation();
    const location = window.location.pathname;
    const [login, setLogin] = useState<{
        logged: boolean,
        role: string
    }>({
        logged: false,
        role: 'user'
    });
    const navigate = useNavigate();

    useEffect(() => {
        const checkConnexion = async () => {
            await Request(AccountRequest()).then((rep: any) => {
                setLogin({
                    logged: true,
                    role: rep.dataRequest.admin_type.type,
                });
            }).catch((error: any) => {
                setLogin({
                    logged: false,
                    role: 'user'
                });
            });
        }
        checkConnexion();
    }, [])

    const handleOnClick = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        if (location !== `${BASE_URL}/timeline`)
            navigate('/timeline');
        setLogin({
            logged: false,
            role: 'user'
        });
    }

    if (login.logged === true) {
        return (
            <DropdownParametre>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {t("Parametre.Setup")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <DropdownItem href={`${BASE_URL}/manage_project`} location={location} text={t("Parametre.Project")} />
                    {
                        login.role === "super_admin" ? 
                            <>
                                <DropdownItem href={`${BASE_URL}/manage_users`} location={location} text={t("Parametre.Users")} />
                                <DropdownItem href={`${BASE_URL}/manage_options`} location={location} text={t("Parametre.Repositories")} />
                                <DropdownItem href={`${BASE_URL}/setup`} location={location} text={t('Parametre.Settings')}/>
                            </> : null
                    }
                    <Dropdown.Item onClick={() => handleOnClick()}>{t("Parametre.Logout")}</Dropdown.Item>
                </Dropdown.Menu>
            </DropdownParametre>
        )
    } else {
        return <Link href={`${BASE_URL}/sign_in`}>Connexion</Link>
    }
}

export default Parameter;
