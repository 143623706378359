import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteDataRequest, Request } from '../../Request/Request';
import { GetTags, PostTags, UpdateTag } from '../../Request/Tags';
import { RootState } from '../../store';

interface TagState {
    idsDelete: number[] | [],
    tags: any[],
    tagsSave: any[],
    error: string | undefined,
    method: string | undefined,
    statusRequest: string | undefined,
    message: string | undefined,
    data: {
        id_project_tag: string,
        name: string
    } | undefined;
}

const initialState: TagState = {
    idsDelete: [],
    tags: [],
    tagsSave: [],
    error: undefined,
    method: undefined,
    statusRequest: undefined,
    message: undefined,
    data: undefined
}

export const fetchTagGet = createAsyncThunk('getTag/', async () => {
    const tag = await GetTags().then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        console.log(error);
    })
    return tag
})

export const fetchTagUpdate = createAsyncThunk('up/tag/:id', async (data: any) => {
    const rep = await Request(UpdateTag(data.id, {name: data.name})).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
    return rep.dataRequest;
})

export const fetchTagPost = createAsyncThunk('/tag', async (data: any) => {
    return await Request(PostTags({name: data.name})).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
})

export const fetchTagDelete = createAsyncThunk('/tag/:id', async (data: {id: string, path: string, name: string}) => {
    const rep = await Request(DeleteDataRequest(data.id, data.path));
    return { ...rep.dataRequest, name: data.name, id_project_tag: data.id };
})

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        addIdsDelete: (state, action) => {
            state.idsDelete = [...state.idsDelete, action.payload];
        },
        removeIdsDelete: (state, action) => {
            state.idsDelete = state.idsDelete.filter((element: any) => element.id_project_tag !== action.payload.id_project_tag);
        },
        addAllIdsDelete: (state, action) => {
            state.idsDelete = action.payload.map((element: any) => element);
        },
        removeAllIdsDelete: (state) => {
            state.idsDelete = [];
        },
        initialOrder: (state) => {
            state.tags = state.tagsSave.filter((elementSave: any) => {
                return state.tags.find((element: any) => elementSave.id_project === element.id_project)
            })
        },
        orderAcs: (state, action) => {
            state.tags = state.tags.sort((a: any, b: any) => {
                return a[action.payload] > b[action.payload] ? 1 : -1;
            })
        },
        orderDesc: (state, action) => {
            state.tags = state.tags.sort((a: any, b: any) => {
                return a[action.payload] < b[action.payload] ? 1 : -1;
            })
        },
    },
    extraReducers(builder) {
        builder
        .addCase(fetchTagGet.pending, (state, action) => {
            state.statusRequest = 'loading'
            state.tags = [];
        })
        .addCase(fetchTagGet.fulfilled, (state, action) => {
            state.statusRequest = 'succeeded'
            state.tags = action.payload.map((element: any) => element);
            state.tagsSave = action.payload.map((element: any) => element);
        })
        .addCase(fetchTagGet.rejected, (state, action) => {
            state.statusRequest = 'failed'
            state.error = action.error.message
        })
        .addCase(fetchTagPost.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchTagPost.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'post',
                error: undefined,
                statusRequest: 'succeeded',
                data: {
                    id_project_tag: action.payload.id_project_tag,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTagPost.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'post',
                error: action.error.message,
                data: {
                    id_project_tag: action.data?.id_project_tag || '',
                    name: action.meta.arg.name,
                }
            }
        })
        .addCase(fetchTagDelete.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchTagDelete.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'delete',
                statusRequest: 'succeeded',
                data: {
                    id_project_tag: action.payload.id_project_tag,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTagDelete.rejected, (state, action) => {
            state.statusRequest = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchTagUpdate.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'update',
                statusRequest: 'succeeded',
                data: {
                    id_project_tag: action.payload.id_project_tag,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTagUpdate.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'update',
                error: action.error.message,
                data: {
                    id_project_tag: action.data?.id_project_tag || '',
                    name: action.meta.arg.name,
                }
            }
        })
    }
})

export const { addIdsDelete, removeIdsDelete, removeAllIdsDelete, addAllIdsDelete, initialOrder, orderAcs, orderDesc } = tagSlice.actions;

export const selectAllTags = (state: RootState) => state.tag.tags;

export const getTagRedux = (state: RootState) => {
    return {
        error: state.tag.error,
        method: state.tag.method,
        statusRequest: state.tag.statusRequest,
        message: state.tag.message,
        data: state.tag.data,
    }
};

export const selectIdsDelete = (state: RootState) => state.tag.idsDelete;

export default tagSlice.reducer