import { configureStore } from '@reduxjs/toolkit'
import { projectsSlice } from './Redux/Projects/Projects'
import { searchSlice } from './Redux/Search/Search'
import { tagSlice } from './Redux/Tag/Tag';
import { clientSlice } from './Redux/Client/Client';
import { typeSlice } from './Redux/Type/Type';
import { userSlice } from './Redux/User/User';
// ...

export const store = configureStore({
  reducer: {
    projects: projectsSlice.reducer,
    search: searchSlice.reducer,
    tag: tagSlice.reducer,
    client: clientSlice.reducer,
    type: typeSlice.reducer,
    user: userSlice.reducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch