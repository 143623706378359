import React from 'react';
import styled from 'styled-components';
import Parameter from '../Parameter/Parameter';
import FilterTimeline from './FilterTimeline';
import ReturnTimeline from './ReturnTimeline';
import { rem } from 'polished';
import HeadersLogo from './Component/HeadersLogo';
import { BASE_URL } from '../../Constant/Constant';

const Headers = styled.header `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 70px;
    padding: 0 ${rem(70)};

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media (max-width: 1440px) {
        padding: 0 ${rem(40)};
    }
`

const Header: React.FC = () => {
    const location = window.location.pathname;

    return (
        <Headers>
            <HeadersLogo />
            <div>
                {
                    location === `${BASE_URL}/timeline` || location == `${BASE_URL}/` ?
                        <FilterTimeline />
                    : null
                }
                <ReturnTimeline />
                <Parameter />
            </div>
        </Headers>
    )
}

export default Header;