//export const HOST_LOCAL = 'http://localhost:8080';
// export const HOST_LOCAL = 'http://13.36.211.155:8080';
// export const HOST_LOCAL = "http://10.65.135.79:8080";

import { BASE_URL } from "./Constant";

export const HOST_LOCAL = "https://demo.ki-timeline.com/api"

export const HOST = `https://demo.ki-timeline.com${BASE_URL || ''}`;

export const HEADER_JSON = {
    'Content-Type': 'application/json',
    "Accept": '*/*',
}

export const HEADER_FORM_DATA = {
    "Content-Type": "multipart/form-data",
    "Accept": "*/*",
    // "type": "formData",
    // 'Access-Control-Allow-Methods': '*',
    // "Access-Control-Allow-Origin": "*",
}
