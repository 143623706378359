import axios from 'axios'
import { HEADER_FORM_DATA, HEADER_JSON, HOST_LOCAL } from '../Constant/Host';

export const UpdateProject = async (id: string, data: FormData) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const project = await axios.put(HOST_LOCAL + '/project/' + id, data, {
        headers: {
            ...HEADER_FORM_DATA,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { data } = rep;
        return data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return project;
}

export const PostProject = async (data: FormData) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const project = await axios.post(HOST_LOCAL + '/project', data, {
        headers: {
            ...HEADER_FORM_DATA,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { data } = rep;
        return data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return project;
}

export const GetProjectsAuth = async () => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const projects = await axios.get(HOST_LOCAL + '/project/admin', {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { data } = rep;
        return data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return projects;
}

export const GetProjecIdAuth = async (id: string) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const projects = await axios.get(HOST_LOCAL + '/project/' + id, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });

    return projects;
}

export const GetProjects = async () => {
    const projects = await axios.get(HOST_LOCAL + '/project', {
        headers: {
            ...HEADER_JSON,
        }
    }).then((rep) => {
        const { data } = rep;
        return data
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data || [] };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });
    return projects;
}

export const GetProjectId = async (id: string) => {
    const projects = await axios.get(HOST_LOCAL + '/project/' + id, {
        headers: {
            ...HEADER_JSON,
        }
    }).then((rep) => {
        return rep.data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });

    return projects;
}

export const GetProjectAttachmentById = async (id: string) => {
    const projectAttachments = await axios.get(HOST_LOCAL + '/project/attachment/' + id, {
        headers: {
            ...HEADER_JSON,
        }
    }).then((rep) => {
        return rep.data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });

    return projectAttachments;
}

export const GetProjectAttachmentDownloadById = async (id: string) => {
    const projectAttachments = await axios.get(HOST_LOCAL + '/project/attachment/download/' + id, {
        responseType: 'blob',
        headers: {
            ...HEADER_FORM_DATA,
        }
    }).then((rep) => {
        return rep.data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });

    return projectAttachments;
}
