import axios from 'axios'
import { HEADER_JSON, HOST_LOCAL } from '../Constant/Host';

export const GetAllUser = async () => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const users = await axios.get(HOST_LOCAL + '/users', {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });

    return users;
}

export const GetUserById = async (id: string) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const users = await axios.get(HOST_LOCAL + '/users/' + id, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        return rep.data;
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });

    return users;
}

export const PostUser = async (data: any) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const users = await axios.post(HOST_LOCAL + '/users', data, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return users;
}
