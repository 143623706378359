import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteDataRequest, Request } from '../../Request/Request';
import { GetAllUser, PostUser } from '../../Request/User';
import { RootState } from '../../store';

interface UserState {
    idsDelete: number[] | [],
    users: any[],
    usersSave: any[],
    error: string | undefined,
    method: string | undefined,
    statusRequest: string | undefined,
    message: string | undefined,
    data: {
        id_user: string,
        first_name: string,
        last_name: string,
        email: string,
    } | undefined;
}

const initialState: UserState = {
    idsDelete: [],
    users: [],
    usersSave: [],
    error: undefined,
    method: undefined,
    statusRequest: undefined,
    message: undefined,
    data: undefined
}

export const fetchGetUsers = createAsyncThunk('get /user', async () => {
    const users = await GetAllUser().then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        console.log(error);
    })
    return users
})

export const fetchUserPost = createAsyncThunk('/user', async (user: any) => {
    return await Request(PostUser(user)).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: user.email };
        throw errorRequest;
    });
})

export const fetchUserDelete = createAsyncThunk('/user/:id', async (data: {id: string, path: string, name: string}) => {
    const rep = await Request(DeleteDataRequest(data.id, data.path));
    return { ...rep.dataRequest, id_project_type: data.id, name: data.name };
})

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addIdsDelete: (state, action) => {
            state.idsDelete = [...state.idsDelete, action.payload];
        },
        removeIdsDelete: (state, action) => {
            state.idsDelete = state.idsDelete.filter((element: any) => element.id_user !== action.payload.id_user);
        },
        addAllIdsDelete: (state, action) => {
            state.idsDelete = action.payload.map((element: any) => element);
        },
        removeAllIdsDelete: (state) => {
            state.idsDelete = [];
        },
        initialOrder: (state) => {
            state.users = state.usersSave.filter((elementSave: any) => {
                return state.users.find((element: any) => elementSave.id_project === element.id_project)
            })
        },
        orderAcs: (state, action) => {
            state.users = state.users.sort((a: any, b: any) => {
                return a[action.payload] > b[action.payload] ? 1 : -1;
            })
        },
        orderDesc: (state, action) => {
            state.users = state.users.sort((a: any, b: any) => {
                return a[action.payload] < b[action.payload] ? 1 : -1;
            })
        },
    },
    extraReducers(builder) {
        builder
        .addCase(fetchGetUsers.pending, (state, action) => {
            state.statusRequest = 'loading'
            state.users = [];
        })
        .addCase(fetchGetUsers.fulfilled, (state, action) => {
            state.statusRequest = 'succeeded'
            state.users = action.payload.map((element: any) => element);
            state.usersSave = action.payload.map((element: any) => element);
        })
        .addCase(fetchGetUsers.rejected, (state, action) => {
            state.statusRequest = 'failed'
            state.error = action.error.message
        })
        .addCase(fetchUserPost.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchUserPost.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'post',
                statusRequest: 'succeeded',
                error: undefined,
                data: {
                    id_user: action.payload.id_user,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    email: action.payload.email,
                }
            }
        })
        .addCase(fetchUserPost.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'post',
                error: action.error.message,
                data: {
                    id_user: '',
                    first_name: action.meta.arg.email,
                    last_name: '',
                    email: action.meta.arg.email,
                }
            }
        })
        .addCase(fetchUserDelete.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchUserDelete.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'delete',
                statusRequest: 'succeeded',
                data: {
                    id_user: action.payload.id_user,
                    first_name: action.payload.name,
                    last_name: action.payload.last_name,
                    email: action.payload.email
                }
            }
        })
        .addCase(fetchUserDelete.rejected, (state, action) => {
            state.statusRequest = 'failed';
            state.error = action.error.message;
        })
    }
})

export const { addIdsDelete, removeIdsDelete, addAllIdsDelete, removeAllIdsDelete, initialOrder, orderAcs, orderDesc } = userSlice.actions;

export const selectAllUsers = (state: RootState) => state.user.users;

export const getUserRedux = (state: RootState) => state.user;

export const selectIdsDelete = (state: RootState) => state.user.idsDelete;

export default userSlice.reducer