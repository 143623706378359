import axios from 'axios'
import { HEADER_JSON, HOST_LOCAL } from '../Constant/Host';
import { RefreshRequest } from "./Sign_in";

type PropsRequest = (
    request: Promise<{ statusRequest: number; dataRequest: any; } | { statusRequest: any; dataRequest: any; }>,
) => any

export const Request: PropsRequest = async (request) => {
    return await request.then((rep: any) => {
        return rep;
    }).catch(async (error: any) => {
        if (error.data.error === 'Token is expired') {
            await RefreshRequest().then((rep: any) => {
                if (rep.statusRequest === 201) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.setItem('access_token', rep.access_token);
                    localStorage.setItem('refresh_token', rep.refresh_token);
                }    
            }).catch((err) => {
                if (err.response !== undefined) {
                    const { status, data } = err.response;
                    const error: any = { status, data }
                    throw error;
                } else {
                    const {statusCode, message} = err;
                    const error: any = { statusCode, message };
                    throw error;
                }
            });
        } else {
            const errorRequest = error;
            throw errorRequest;
        }
    })
}


export const DeleteDataRequest = async (id: string, path: string) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const deleteData = await axios.delete(HOST_LOCAL + path + id, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });
    return deleteData;
}