import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BASE_URL, COLOR, COLOR_HOVER } from '../../Constant/Constant';

const ReturnTimelineComponent = styled.div `
    @media (max-width: 590px) {
        display: none;
    }
`

const Link = styled.a `
    display: inline-block;
    border: 1px solid ${COLOR};
    background: ${COLOR};
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font: normal normal normal 12px/27px InterBold;
    padding: 0 10px;

    :hover {
        background: ${COLOR_HOVER};
        color: white;
    }
`

const ReturnTimeline: React.FC = () => {
    
    const location = window.location.pathname;
    const { t } = useTranslation();

    if (location !== `${BASE_URL}/timeline` && location !== `${BASE_URL}/`)
        return (
            <ReturnTimelineComponent>
                <Link href='timeline'>{t("Header.Link_See_Timeline")}</Link>
            </ReturnTimelineComponent>
        )
    return null
}

export default ReturnTimeline;