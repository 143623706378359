import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetClient, PostClient, UpdateClient } from '../../Request/Client';
import { DeleteDataRequest, Request } from '../../Request/Request';
import { RootState } from '../../store';

interface ClientState {
    idsDelete: number[] | [],
    clients: any[],
    clientsSave: any[],
    error: string | undefined,
    method: string | undefined,
    statusRequest: string | undefined,
    message: string | undefined,
    data: {
        id_project_client: string,
        name: string,
    } | undefined;
}

const initialState: ClientState = {
    idsDelete: [],
    clients: [],
    clientsSave: [],
    error: undefined,
    method: undefined,
    statusRequest: undefined,
    message: undefined,
    data: undefined
}

export const fetchGetClients = createAsyncThunk('getClient', async () => {
    const clients = await GetClient().then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        console.log(error);
    })
    return clients
})

export const fetchClientUpdate = createAsyncThunk('up/client/:id', async (data: any) => {
    return await Request(UpdateClient(data.id, data)).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
})

export const fetchClientPost = createAsyncThunk('/client', async (data: any) => {
    return await Request(PostClient(data)).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
})

export const fetchClientDelete = createAsyncThunk('/client/:id', async (data: {id: string, path: string, name: string}) => {
    const rep = await Request(DeleteDataRequest(data.id, data.path));
    return { ...rep.dataRequest, name: data.name, id_project_client: data.id };
})

export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        addIdsDelete: (state, action) => {
            state.idsDelete = [...state.idsDelete, action.payload];
        },
        removeIdsDelete: (state, action) => {
            state.idsDelete = state.idsDelete.filter((element: any) => element.id_client !== action.payload.id_client);
        },
        addAllIdsDelete: (state, action) => {
            state.idsDelete = action.payload.map((element: any) => element);
        },
        removeAllIdsDelete: (state) => {
            state.idsDelete = [];
        },
        initialOrder: (state) => {
            state.clients = state.clientsSave.filter((elementSave: any) => {
                return state.clients.find((element: any) => elementSave.id_project === element.id_project)
            })
        },
        orderAcs: (state, action) => {
            state.clients = state.clients.sort((a: any, b: any) => {
                return a[action.payload] > b[action.payload] ? 1 : -1;
            })
        },
        orderDesc: (state, action) => {
            state.clients = state.clients.sort((a: any, b: any) => {
                return a[action.payload] < b[action.payload] ? 1 : -1;
            })
        },
    },
    extraReducers(builder) {
        builder
        .addCase(fetchGetClients.pending, (state, action) => {
            state.statusRequest = 'loading'
            state.clients = [];
        })
        .addCase(fetchGetClients.fulfilled, (state, action) => {
            state.statusRequest = 'succeeded'
            state.clients = action.payload.map((element: any) => element);
            state.clientsSave = action.payload.map((element: any) => element);
        })
        .addCase(fetchGetClients.rejected, (state, action) => {
            state.statusRequest = 'failed'
            state.error = action.error.message
        })
        .addCase(fetchClientPost.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchClientPost.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'post',
                statusRequest: 'succeeded',
                data: {
                    id_project_client: action.payload.id_client,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchClientPost.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'post',
                error: action.error.message,
                data: {
                    id_project_client: action.data?.id_project_client || '',
                    name: action.meta.arg.name,
                }
            }
        })
        .addCase(fetchClientDelete.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchClientDelete.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'delete',
                statusRequest: 'succeeded',
                data: {
                    id_project_client: action.payload.id_client,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchClientDelete.rejected, (state, action) => {
            state.statusRequest = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchClientUpdate.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchClientUpdate.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'update',
                statusRequest: 'succeeded',
                data: {
                    id_project_client: action.payload.id_client,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchClientUpdate.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'update',
                error: action.error.message,
                data: {
                    id_project_client: action.data?.id_project_client || '',
                    name: action.meta.arg.name,
                }
            }
        })
    }
})

export const { addIdsDelete, removeIdsDelete, addAllIdsDelete, removeAllIdsDelete, initialOrder, orderAcs, orderDesc } = clientSlice.actions;

export const selectAllClients = (state: RootState) => state.client.clients;

export const getClientRedux = (state: RootState) => {
    return {
        error: state.client.error,
        method: state.client.method,
        statusRequest: state.client.statusRequest,
        message: state.client.message,
        data: state.client.data,
    }
};

export const selectIdsDelete = (state: RootState) => state.client.idsDelete;

export default clientSlice.reducer