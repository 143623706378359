import React from 'react';
import styled from 'styled-components';

type PropsText = {
    font: string;
    size: string;
    color: string;
    children: string | Element | any;
    onClick?: any;
}

const TextComponent = styled.p <{font: string, size: string, color: string}>`
    font: normal normal normal ${props => props.size} ${props => props.font};
    letter-spacing: 0px;
    white-space: pre-line;
    color: ${props => props.color};
`

const Text: React.FC<PropsText> = ({font, size, color, children, onClick}) => {

    const handleOnClick = () => {
        if (onClick !== undefined) {
            onClick();
        }
    }

    return (
        <TextComponent onClick={handleOnClick} font={font} size={size} color={color}>
            {children}
        </TextComponent>
    )
}

export default Text;