import React, { lazy } from 'react';
import { OPEN_TIMELINE } from '../../Constant/Constant';
import PrivateRoute from './PrivateRoute';
import RouterWithHeader from './RouterWithHeader';

const TimelineComponent = lazy(() => import('../../Page/Timeline'));

type PropsRouterIsLogin = {
    login: {
        logged: boolean,
        role: string
    }
}

const RouterIsLogin: React.FC<PropsRouterIsLogin> = ({login}) => {
    if (OPEN_TIMELINE) {
        return (
            <PrivateRoute path='/timeline' login={login}>
                <RouterWithHeader Component={<TimelineComponent />} />
            </PrivateRoute>
        )
    }
    return (
        <RouterWithHeader Component={<TimelineComponent />} />
    )
}

export default RouterIsLogin;