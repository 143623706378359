import React, { useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { OPEN_TIMELINE } from '../../Constant/Constant';

const PrivateRoute: React.FC<any> = ({ children, path, login }) => {

    const checkToken = useCallback(() => {
        if (login.logged) {
            if (login.role === 'super_admin') {
                if (path === '/manage_project' || path.split('/')[1] === 'projects' || 
                    path === '/manage_options' || path === '/manage_users' || 
                    path === '/setup' || (path === '/timeline' && OPEN_TIMELINE)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (path === '/manage_project' || path.split('/')[1] === 'projects' || (path === '/timeline' && OPEN_TIMELINE)) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    }, [login, path])

    let isConnect:any = checkToken();

    if (isConnect) {
        return children;
    } else {
	    if (OPEN_TIMELINE)
        	return <Navigate to={"/sign_in"} />
	    return <Navigate to={"/"}/>
    }
}

export default PrivateRoute;
