import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components';
import { selectAllProjects } from '../../Redux/Projects/Projects';
import { addSearchResult } from './../../Redux/Search/Search';
import { AppDispatch } from '../../store';
import Input from './Input';
import Icon from './InputImage';
import { useTranslation } from 'react-i18next';
import Text from '../Text/Text';

const ElementList: React.FC<any> = ({element, onClick, setOpen}) => {

    const handleOnClick = (event: any, element: any): any => {
        event.preventDefault();
        onClick({
            name: 'search',
            value: element,
        })
        setOpen(false);
    }

    return (
        <li onClick={(e) => handleOnClick(e, element)}>
            <Text font={"InterBold"} size={"10px/14px"} color={"#2B2B2B"}>{element.name}</Text>
            <Text font={"InterRegular"} size={"10px/13px"} color={"#2B2B2B"}>{element.client.map((element: any) => element.name + ' ')}</Text>
        </li>
    )
}

type PropsListSearch = {
    result: {
        id_project: string;
        name: string;
        client: {
            id: string;
            name: string;
        }[];
    }[] | string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: any;
}

const ListDrop = styled.div `
    position: absolute;
    width: 100%;
    max-width: 250px;
    overflow: auto;
    background: white;
    z-index: 100;
    max-height: 100%;
    border-radius: 0px 0px 5px 5px;
    margin-top: 0px;
    border: 0.5px solid #2B2B2B;
    border-top: 0;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
        padding: 0px 0px 0px 0px;
        margin-bottom: 0px;
        overflow: hidden;
    }

    li {
        max-height: 38px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border-bottom: 0.5px solid #B5B5B6;
        padding: 7px 0px 5px 30px;
    }

    li:last-child {
        border-bottom: none;
    }
`

const ListSearch: React.FC<PropsListSearch> = ({result, open, setOpen, onClick}) => {

    const ClosePopUpClickOutside = (
        ref: React.MutableRefObject<any>,
        open: Boolean,
        setOpen: React.Dispatch<React.SetStateAction<boolean>>
    ) => {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (ref !== null) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setOpen(!open);
                    }
                }
            }
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [ref, open, setOpen]);
    }

    const wrapperRef = useRef(null)
    ClosePopUpClickOutside(wrapperRef, open, setOpen);

    if (result.length !== 0 && open) {
        return (
            <ListDrop ref={wrapperRef}>
                <ul>
                    {
                        typeof result === 'string' || result instanceof String ?
                            <li><Text font={"InterBold"} size={"10px/13px"} color={"#2B2B2B"}>Aucune correspondance trouvée .</Text></li> :
                            result.map((element) => {
                                return <ElementList key={element.id_project} element={element} onClick={onClick} setOpen={setOpen} />
                            })
                    }
                </ul>
            </ListDrop>
        )    
    }
    return null;
}

type PropsSearch = {
    
}

const SearchBar: React.FC<PropsSearch> = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const projects = useSelector(selectAllProjects);
    const [result, setResult] = useState<{
        id_project: string;
        name: string;
        client: {
            id: string;
            name: string;
        }[];
    }[] | string>([]);
    const [value, setValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);

    const handleOnChange = (event: {name: string, value: string}) => {
        if (event.name === "search") {
            dispatch(addSearchResult(0));
        }
        if (event.value) {
            const regex = RegExp('^' + event.value, 'i');
            const search = projects.projects.filter((element: any) => Object.keys(element).some(
                (keys) => regex.test(element['client'].map((element: any) => element.name)) || regex.test(element['project_type'].map((element: any) => element.name)) || regex.test(element['name'])))
            if (event.value.length !== 0)
                if (search.length !== 0) {
                    setResult((prevItems: any) => search);
                } else {
                    setResult('Error');
                }
                setOpen(true);
        } else {
            setResult([]);
        }
        setValue(event.value);
    }

    const handleOnClick = (event: {name: string, value: any}) => {
        dispatch(addSearchResult(event.value.id_project))
        setValue(event.value.name);
    }

    return (
        <div>
            <Input Icon={Icon} open={open} name="search" type="text" placeholder={t("Header.Input_Search_Placeholder")} handleOnChange={handleOnChange} value={value} required={undefined} />
            { open ? <ListSearch result={result} open={open} setOpen={setOpen} onClick={handleOnClick}/> : false }
        </div>
    )
}

export default SearchBar;