import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteDataRequest, Request } from '../../Request/Request';
import { GetType, PostType, UpdateType } from '../../Request/Type';
import { RootState } from '../../store';

interface TypeState {
    idsDelete: number[] | [],
    types: any[],
    typesSave: any[],
    error: string | undefined,
    method: string | undefined,
    statusRequest: string | undefined,
    message: string | undefined,
    data: {
        id_project_type: string,
        name: string
    } | undefined;
}

const initialState: TypeState = {
    idsDelete: [],
    types: [],
    typesSave: [],
    error: undefined,
    method: undefined,
    statusRequest: undefined,
    message: undefined,
    data: undefined
}

export const fetchTypesGet = createAsyncThunk('getType', async () => {
    const types = await GetType().then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        console.log(error);
    })
    return types;
})

export const fetchTypeUpdate = createAsyncThunk('up/type/:id', async (data: any) => {
    return await Request(UpdateType(data.id, {name: data.name})).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
})

export const fetchTypePost = createAsyncThunk('/type', async (data: any) => {
    return await Request(PostType({name: data.name})).then((rep: any) => {
        return rep.dataRequest;
    }).catch((error: any) => {
        const errorRequest: any = { status: error.status, message: error.data.error, data: data.name };
        throw errorRequest;
    });
})

export const fetchTypeDelete = createAsyncThunk('/type/:id', async (data: {id: string, path: string, name: string}) => {
    const rep = await Request(DeleteDataRequest(data.id, data.path));
    return { ...rep.dataRequest, name: data.name, id_project_type: data.id };
})

export const typeSlice = createSlice({
    name: 'type',
    initialState,
    reducers: {
        addIdsDelete: (state, action) => {
            state.idsDelete = [...state.idsDelete, action.payload];
        },
        removeIdsDelete: (state, action) => {
            state.idsDelete = state.idsDelete.filter((element: any) => element !== action.payload);
        },
        addAllIdsDelete: (state, action) => {
            state.idsDelete = action.payload.map((element: any) => element);
        },
        removeAllIdsDelete: (state) => {
            state.idsDelete = [];
        },
        initialOrder: (state) => {
            state.types = state.typesSave.filter((elementSave: any) => {
                return state.types.find((element: any) => elementSave.id_project === element.id_project)
            })
        },
        orderAcs: (state, action) => {
            state.types = state.types.sort((a: any, b: any) => {
                return a[action.payload] > b[action.payload] ? 1 : -1;
            })
        },
        orderDesc: (state, action) => {
            state.types = state.types.sort((a: any, b: any) => {
                return a[action.payload] < b[action.payload] ? 1 : -1;
            })
        },
    },
    extraReducers(builder) {
        builder
        .addCase(fetchTypesGet.pending, (state, action) => {
            state.statusRequest = 'loading'
            state.types = [];
        })
        .addCase(fetchTypesGet.fulfilled, (state, action) => {
            state.statusRequest = 'succeeded'
            state.types = action.payload.map((element: any) => element);
            state.typesSave = action.payload.map((element: any) => element);
        })
        .addCase(fetchTypesGet.rejected, (state, action) => {
            state.statusRequest = 'failed'
            state.error = action.error.message
        })
        .addCase(fetchTypePost.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchTypePost.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'post',
                statusRequest: 'succeeded',
                data: {
                    id_project_type: action.payload.id_project_type,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTypePost.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'post',
                error: action.error.message,
                data: {
                    id_project_type: action.data?.id_project_type || '',
                    name: action.meta.arg.name,
                }
            }
        })
        .addCase(fetchTypeDelete.pending, (state, action) => {
            state.statusRequest = 'loading';
        })
        .addCase(fetchTypeDelete.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'delete',
                statusRequest: 'succeeded',
                data: {
                    id_project_type: action.payload.id_project_type,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTypeDelete.rejected, (state, action) => {
            state.statusRequest = 'failed';
            state.error = action.error.message;
        })
        .addCase(fetchTypeUpdate.fulfilled, (state, action) => {
            return {
                ...state,
                method: 'update',
                statusRequest: 'succeeded',
                data: {
                    id_project_type: action.payload.id_project_type,
                    name: action.payload.name,
                }
            }
        })
        .addCase(fetchTypeUpdate.rejected, (state, action: any) => {
            return {
                ...state,
                statusRequest: 'failed',
                method: 'update',
                error: action.error.message,
                data: {
                    id_project_type: action.data?.id_project_type || '',
                    name: action.meta.arg.name,
                }
            }
        })
    }
})

export const { addIdsDelete, removeIdsDelete, addAllIdsDelete, removeAllIdsDelete, initialOrder, orderAcs, orderDesc } = typeSlice.actions;

export const selectAllTypes = (state: RootState) => state.type.types;

export const getTypeRedux = (state: RootState) => {
    return {
        error: state.type.error,
        method: state.type.method,
        statusRequest: state.type.statusRequest,
        message: state.type.message,
        data: state.type.data,
    }
};

export const selectIdsDelete = (state: RootState) => state.type.idsDelete;

export default typeSlice.reducer