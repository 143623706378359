import React from 'react';
// import { ReactComponent as LogoKiSvg } from './../../asset/images/logo/logo-geodis.svg';
import { ReactComponent as LogoKiSvg } from './../../asset/images/logo/logo-ki.svg';
// import { ReactComponent as LogoSvg } from './../../asset/images/logo.svg';
import { ReactComponent as ArrowFilterSvg } from './../../asset/images/arrow-filters.svg';
import { ReactComponent as ArrowSvg } from './../../asset/images/arrownav.svg';
import { ReactComponent as MoreSvg } from './../../asset/images/more.svg';
import { ReactComponent as DeleteSvg } from './../../asset/images/delete.svg';
import { ReactComponent as CheckSvg } from './../../asset/images/check.svg';
import { ReactComponent as EditSvg } from './../../asset/images/edit.svg';
import { ReactComponent as CloseSvg } from './../../asset/images/close.svg';
import { ReactComponent as FullScreenSvg } from './../../asset/images/fullscreen.svg';
import { ReactComponent as NothingSvg } from './../../asset/images/nothing.svg';
import { ReactComponent as SeeSvg } from './../../asset/images/see.svg';
import { ReactComponent as WarningSvg } from './../../asset/images/warning.svg';
import { ReactComponent as SaveSvg } from './../../asset/images/save.svg';
import { ReactComponent as AddSvg } from './../../asset/images/add.svg';
import { ReactComponent as PdfSvg } from './../../asset/images/pdf-icon.svg';
import { ReactComponent as VideoSvg } from './../../asset/images/video-icon.svg';
import { ReactComponent as ImportSvg } from './../../asset/images/import.svg';
import { ReactComponent as LogoTextKi } from './../../asset/images/logo-kitext.svg';
import { BASE_URL } from '../../Constant/Constant';
import styled from 'styled-components';

type PropsSvgComponent = {
    open?: boolean | undefined;
    onClick?: () => void;
    width?: string;
    height?: string;
    color?: string;
    direction?: 'left' | 'right' | 'top' | 'bottom';
}

export const Logo: React.FC<PropsSvgComponent> = () => {
    const location = window.location.pathname;

    if (location !== `${BASE_URL}/sign_in`) {
        return (
            <LogoKiSvg style={{cursor: 'pointer'}} onClick={event => window.location.href=`${BASE_URL}/timeline`} width={'40px'} height={`100%`}/>
            // <LogoKiSvg style={{cursor: 'pointer'}} onClick={event => window.location.href='/timeline'} width={'10rem'} height={`100%`}/>
        )
    }
    return <LogoKiSvg width={'40px'} height={`100%`}/>
    // return <LogoKiSvg width={'10rem'} height={`100%`}/>
}

export const LogoKiTextSvg: React.FC = () => {
    return (
        <LogoTextKi width={130} height={95} />
    )
}

export const ArrowFilter: React.FC<PropsSvgComponent> = ({open, width, height}) => {
    return (
        <ArrowFilterSvg style={{transform: open ? 'rotate(0deg)' : 'rotate(180deg)'}} width={width || '7px'} height={height || `7px`}/>
    )
}

export const Save: React.FC<PropsSvgComponent> = () => {
    return <SaveSvg />
}

export const More: React.FC<PropsSvgComponent> = () => {
    return <MoreSvg width={'13px'} height={`13px`} />
}

const EditSvgStyled = styled(EditSvg) <{color: string}>`
    & line,
    & path {
        stroke: ${props => props.color};
    }
`

export const Edit: React.FC<PropsSvgComponent> = ({onClick, color, width, height}) => {
    return <EditSvgStyled color={color || '#B5B5B6'} onClick={onClick} width={width || '12px'} height={height || '12px'} />
}

const DeleteSvgStyled = styled(DeleteSvg) <{color: string}>`
    & line,
    & path,
    & g {
        stroke: ${props => props.color};
    }
`

export const Delete: React.FC<PropsSvgComponent> = ({onClick, color}) => {
    return <DeleteSvgStyled color={color || '#B5B5B6'} onClick={onClick} width={'12px'} height={`12px`} />
}

const SeeSvgStyled = styled(SeeSvg) <{color: string}>`
    & line,
    & path {
        stroke: ${props => props.color};
    }
`

export const See: React.FC<PropsSvgComponent> = ({onClick, color}) => {
    return <SeeSvgStyled color={color || '#B5B5B6'} onClick={onClick} width={'14px'} height={'11px'} />
}

const CheckSvgStyled = styled(CheckSvg) <{color: string}>`
    & line,
    & path {
        fill: ${props => props.color};
    }
`

export const Check: React.FC<PropsSvgComponent> = ({color, width, height}) => {
    return <CheckSvgStyled color={color || '#white'} width={width || '14px'} height={height || '11px'}/>
}

const CloseSvgStyled = styled(CloseSvg) <{color: string}>`
    & line,
    & path {
        stroke: ${props => props.color};
    }
`

export const Close: React.FC<PropsSvgComponent> = ({onClick, color, width, height}) => {
    return <CloseSvgStyled color={color || 'white'} onClick={onClick} width={width || '8px'} height={height || '8px'} />
}

export const FullScreenIcone: React.FC<PropsSvgComponent> = () => {
    return <FullScreenSvg  width={'10px'} height={'10px'} />
}

export const Nothing: React.FC<PropsSvgComponent> = () => {
    return <NothingSvg width={'103px'} height={'103px'} />
}

export const Warning: React.FC<PropsSvgComponent> = () => {
    return <WarningSvg width={'40px'} height={'30px'} />
}

export const Arrow: React.FC<PropsSvgComponent> = ({direction}) => {
    return <ArrowSvg style={{transform: direction === 'left' ? 'rotate(0deg)' : 'rotate(180deg)'}} width={'14px'} height={'12px'} />
}

export const Import: React.FC<PropsSvgComponent> = () => {
    return <ImportSvg width={'74px'} height={'74px'} />
}

export const Add: React.FC<PropsSvgComponent> = () => {
    return <AddSvg width={'12px'} height={'12px'} />
}

export const Pdf: React.FC<PropsSvgComponent> = () => {
    return <PdfSvg width={'23px'} height={'30px'} />
}

export const Video: React.FC<PropsSvgComponent> = () => {
    return <VideoSvg width={'23px'} height={'30px'} />
}