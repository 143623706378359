import React from 'react';
import { TitleHeader } from '../../Title/Title';
import { COLOR } from '../../../Constant/Constant';
import { Logo } from '../../Image/Icone';
import styled from 'styled-components';

const HeadersLogoComponent = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
`

const HeadersLogo: React.FC = () => {
    return (
        <HeadersLogoComponent>
            <Logo />
            <TitleHeader font={"InterSemiBold"} size={"14px/23px"} color={COLOR}>/Timeline</TitleHeader>
        </HeadersLogoComponent>
    )
}

export default HeadersLogo;