import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

interface SearchState {
    id: number;
    numberSlider: number;
}

const initialState: SearchState = {
    id: 0,
    numberSlider: 0
}

export const searchSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addSearchResult: (state, actions) => {
            state.id = actions.payload;
        }
    }
})

export const { addSearchResult } = searchSlice.actions

export const selectSearchResult = (state: RootState) => state.search;

export default searchSlice.reducer