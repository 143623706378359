import React from 'react';
import styled from 'styled-components';

type PropsTitle = {
    title?: any;
    font: string;
    size: string;
    color: string;
    children: string;
}

const TitleHeaderComponent = styled.h1 <{font: string, size: string, color: string}>`
    font: normal normal normal ${props => props.size} ${props => props.font};
    letter-spacing: 0px;
    color: ${props => props.color};
`

export const TitleHeader: React.FC<PropsTitle> = ({ font, size, color, children }) => {
    return (
        <TitleHeaderComponent font={font} size={size} color={color}>
            {children}
        </TitleHeaderComponent>
    )
}

const TitleSecondeComponent = styled.h2 <{font: string, size: string, color: string}>`
    font: normal normal normal ${props => props.size} ${props => props.font};
    letter-spacing: 0px;
    color: ${props => props.color};
    white-space: pre-line;
`

export const TitleSecond: React.FC<PropsTitle> = ({ font, size, color, children }) => {
    return (
        <TitleSecondeComponent font={font} size={size} color={color}>
            {children}
        </TitleSecondeComponent>
    )
}

const TitleFicheComponent = styled.h3 <{font: string, size: string, color: string}>`
    font: normal normal normal ${props => props.size} ${props => props.font};
    letter-spacing: 0px;
    color: ${props => props.color};
`

export const TitleFiche: React.FC<PropsTitle> = ({ font, size, color, children }) => {
    return (
        <TitleFicheComponent font={font} size={size} color={color}>
            {children}
        </TitleFicheComponent>
    )
}
