import axios from 'axios';
import { HOST_LOCAL, HEADER_JSON } from '../Constant/Host'

export const GetType = async () => {
    const type = await axios.get(HOST_LOCAL + '/type', {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });
    return type;
}

export const GetTypeById = async (id: string) => {
    const type = await axios.get(HOST_LOCAL + '/type/' + id, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return type;
}

export const PostType = async (type: { name: string }) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const typeData = await axios.post(HOST_LOCAL + '/type', type, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return typeData;
}

export const UpdateType = async (
    id: string,
    type: {
        name: string
    }) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const typeData = await axios.put(HOST_LOCAL + '/type/' + id, type, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return typeData;
}