import axios from 'axios'
import { HOST_LOCAL, HEADER_JSON } from '../Constant/Host'

export const GetClient = async () => {
    const clients = await axios.get(HOST_LOCAL + '/client', {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return clients
}

export const GetClientAdmin = async () => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const clients = await axios.get(HOST_LOCAL + '/client/admin', {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return clients
}

export const GetClientByIdRequest = async (id: string) => {
    const clients = await axios.get(HOST_LOCAL + '/client/' + id, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });
    return clients
}

export const PostClient = async (client: {
    name: string,
    main_color: string,
}) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const clients = await axios.post(HOST_LOCAL + '/client', client, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return clients
}

export const UpdateClient = async (id: string, client: {
    name: string,
    main_color: string,
}) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const clients = await axios.put(HOST_LOCAL + '/client/' + id, client, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return clients
}