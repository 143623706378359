import axios from 'axios';
import { HOST_LOCAL, HEADER_JSON } from '../Constant/Host';

export const GetTags = async () => {
    const tags = await axios.get(HOST_LOCAL + '/tag', {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return tags;
}

export const GetTagsById = async (id: string) => {
    const tags = await axios.get(HOST_LOCAL + '/tag/' + id, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            return { statusRequest: status, dataRequest: data };
        } else {
            const {statusCode, message} = err;
            return { statusRequest: statusCode, dataRequest: message };
        }
    });
    return tags;
}


export const PostTags = async (filter: {name: string}) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const tags = await axios.post(HOST_LOCAL + '/tag', filter, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return tags;
}

export const UpdateTag = async ( id: string, filter: { name: string } ) => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const tags = await axios.put(HOST_LOCAL + '/tag/' + id, filter, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return tags;
}