import React, { useEffect, useState } from 'react';
import SelectObject from '../Input/SelectObject';
import SearchBar from '../Input/SearchBar';
import { GetClient } from '../../Request/Client';
import { GetTags } from '../../Request/Tags';
import { GetType } from '../../Request/Type';
import { GetProjectId, GetProjects } from '../../Request/Project';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { filterProjects } from '../../Redux/Projects/Projects';
import styled from 'styled-components';
import { BASE_URL, FILTRE_0, FILTRE_1, FILTRE_2, FILTRE_3 } from '../../Constant/Constant';
import { useTranslation } from 'react-i18next';

type PropsFilterTimeLine = {
    
}

type PropsElementFiler = {
    delivered_at: {label: string, value: string}[] | [],
    client: {label: string, value: string}[] | [],
    project_tag: {label: string, value: string}[] | [],
    project_type: {label: string, value: string}[] | [],
}

const Filter = styled.div `
    display: flex;

    & > div:nth-child(1),
    & > div:nth-child(2),
    & > div:nth-child(3),
    & > div:nth-child(4) {
        margin: 0 10px 0 0;
    }

    @media (max-width: 1250px) {
        & > div:nth-child(2),
        & > div:nth-child(3),
        & > div:nth-child(4),
        & > div:nth-child(5) {
            display: none;
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
`

const FilterTimeline: React.FC<PropsFilterTimeLine> = () => {

    const location = window.location.pathname;

    const { t } = useTranslation();
    const [elementFilter, setElementFilter] = useState<PropsElementFiler>({
        delivered_at: [],
        client: [],
        project_tag: [],
        project_type: []
    });
    const dispatch = useDispatch<AppDispatch>();
    const projectSave = useSelector((state: any) => state.projects.projectsSave)
    const [elementFilterSelected, setelementFilterSelected] = useState<PropsElementFiler>({
        delivered_at: [],
        client: [],
        project_tag: [],
        project_type: []
    })

    const dateOptionFilter = async () => {
        const projects = await GetProjects().then((rep: any) => {
            return rep;
        }).catch((error: any) => {
            console.log(error);
        })
        const allDateProject = projects.map((element: any) => element.delivered_at.split('-')[0]);
        const dateProject = allDateProject.filter((element: any, index: any) => {
            return allDateProject.indexOf(element) === index;
        })
        return dateProject.map((element: any) => ({label: element, value: element}));
    }

    useEffect(() => {
        const getFilterInfo = async () => {
            const clientOptions = await (await GetClient()).dataRequest.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((element: any) => ({label: element.id_client, value: element.name}));
            const typeOptions = await (await GetType()).dataRequest.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((element: any) => ({label: element.id_project_type, value: element.name}));
            const tagOptions = await (await GetTags()).dataRequest.sort((a: any, b: any) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((element: any) => ({label: element.id_project_tag, value: element.name}));
            setElementFilter({
                delivered_at: await dateOptionFilter(),
                client: clientOptions,
                project_type: typeOptions,
                project_tag: tagOptions
            })
        }
        getFilterInfo();
    }, [])

    useEffect(() => {
        const getAllDataProjects = async () => {
            const projects = await new Promise( async resolve => {
                await Promise.all(
                    projectSave.map(async (element: any) => {
                        return GetProjectId(element.id_project).then((rep: any) => {
                            return rep;
                        }).catch((error) => {
                            console.log('error', error);
                        })
                    })
                ).then((rep) => {
                    resolve(rep);
                })
            })
            dispatch(filterProjects({...elementFilterSelected, projects}));
        }
        getAllDataProjects()
    }, [dispatch, elementFilterSelected, projectSave])

    const handleOnChange = async (newValue: any, name: string) => {
        setelementFilterSelected((previous: any) => {
            return { ...previous, [name]: newValue}
        })
    }

    return (
        <Filter>
            <SearchBar />
            <SelectObject name='delivered_at' placeholder={t("Header.Select_Year")} options={elementFilter.delivered_at} onChange={handleOnChange}/>
            <SelectObject name='client' placeholder={FILTRE_1} options={elementFilter.client} onChange={handleOnChange}/>
            <SelectObject name='project_type' placeholder={FILTRE_2} options={elementFilter.project_type} onChange={handleOnChange}/>
            <SelectObject name='project_tag' placeholder={FILTRE_3} options={elementFilter.project_tag} onChange={handleOnChange}/>
        </Filter>
    )
}

export default FilterTimeline;
