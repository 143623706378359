import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import checksvg from './../../asset/images/check.svg';
import { COLOR } from '../../Constant/Constant';

type PropsSelectObject = {
    name: string;
    placeholder: string;
    options: {
        label: string;
        value: string;
    }[];
    onChange: any;
}

const SelectFilter = styled(Select)<{optionsNumber: number}>`
    min-width: 130px;
    width: 130px;
    height: 30px;
    z-index: 101;

    & > div:hover {
        border-color: #2B2B2B;
    }

    & > div:nth-child(3) > div:first-child > div:first-child {
        font: normal normal normal 12px/16px InterRegular;
        white-space: pre;
    }

    & > div:nth-child(3) > div:first-child .label_element div {
        height: 0;
        max-height: 0;
        min-height: 0;
        position: absolute;
        top: -4px;
    }

    & > div:nth-child(3), & > div:nth-child(3) > div:nth-child(2) {
        height: 30px;
        min-height: 30px;
    }

    & div {
        max-height: calc(100vh - 60px - 100px);
    }

    // & > div:nth-child(4) > div {
    //     border: 0.5px solid #2B2B2B;
    // }

    & > div span {
        width: 0;
    }

    & > div svg {
        width: 13px;
    }

    & > div .options {
        border-bottom: 0.5px solid #B5B5B6;
    }

    & > div .options:last-child {
        border-bottom: none;
    }

    @media (max-width: 1700px) {
        width: 100px;
    }
`

const SelectOption = styled.div `
    display: flex;
    flex-direction: row;
    align-items: center;
    font: normal normal normal 12px/12px InterRegular;
`

const Check = styled(Form.Check) `
    input {
        margin-top: 0;
        // border-radius: 10px;
        width: 1.10em;
        height: 1.10em;
    }

    .form-check-input:checked {
        background-color: white;
        border-color: ${COLOR};
    }

    .form-check-input[type="checkbox"] {
        // border-radius: 10px;
    }

    .form-check-input:checked[type="checkbox"] {
        background-image: url(${checksvg});
        background-size: 10px 10px;
        // border-radius: 10px;
    }
`

const OptionComponent = (props: any) => {
    return (
        <components.Option className='options' style={{backgroupColors: 'transparent', color: '#2B2B2B'}} {...props}>
            <SelectOption style={{font: 'normal normal normal 12px/12px InterRegular'}}>
                <Check type='checkbox' inline checked={props.isSelected} readOnly/> 
                {props.value}
            </SelectOption>
        </components.Option>
    )
}

const ValueComponent = ({ children, hasValue, ...props }: any) => {
    const { label, required } = props.selectProps;
    if (!hasValue) {
        return (
          <components.ValueContainer {...props}>
            <div className={'label_element'}>
                {children[1].props.selectProps.placeholder}
                {children[1]}
            </div>
          </components.ValueContainer>
        );
    }
    const text = children[1].props.getValue().length + ' ' + children[1].props.selectProps.placeholder + 's';
    return (
        <components.ValueContainer style={{display: "inline-flex", flexDirection: 'row'}} {...props}>
            <div className={'label_element'}>
                {children[1].props.getValue().length + ' ' + children[1].props.selectProps.placeholder + 's'}
                {children[1]}
            </div>
        </components.ValueContainer>
    )
}

const SelectComponent = ({children, ...props}: any) => {
    return (
        <components.SelectContainer {...props}>
            {children}
        </components.SelectContainer>
    )    
}

const MenuComponent = ({children, ...props}: any) => {
    if (props.selectProps.menuIsOpen) {
        return (
            <components.Menu {...props}>
                {children}
            </components.Menu>
        )
    }
    return null;
}

const SelectObject: React.FC<PropsSelectObject> = ({name, placeholder, options, onChange}) => {

    const wrapperRef = useRef<any>(null);
    const [valueSelect, setValueSelect] = useState<{
        label?: string | undefined;
        value?: string | undefined;
    }[]>([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleChange = (newValue: any, actionMeta: any) => {
        setValueSelect(newValue);
        onChange(newValue, name);
    }

    return (
        <SelectFilter optionsNumber={options.length} name={name}
            ref={wrapperRef}
            placeholder={placeholder}
            closeMenuOnSelect={false}
            options={options}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            menuIsOpen={menuIsOpen}
            styles={{
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? 'transparent' : 'transparent',
                    color: '#2B2B2B',
                    cursor: 'pointer',
                }),
                menu: (provided, state) => ({
                    ...provided,
                    minWidth: '200px',
                    margin: '0px',
                    border: "0.5px solid #2B2B2B",
                    borderColor: "#2B2B2B",
                    borderRadius: "0px 0px 5px 5px"
                }),
                clearIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? "white" : "#2B2B2B",
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? "white" : "#2B2B2B",
                    transform : state.isFocused ? "rotate(0)" : "rotate(-90deg)",
                }),
                control: (provided, state) => ({
                    ...provided,
                    height: `30px`,
                    backgroundColor: state.isFocused ? "#2B2B2B" : "transparent",
                    color: state.isFocused ? "white" : "#2B2B2B",
                    borderRadius: state.menuIsOpen ? "5px 5px 0px 0px" : "5px 5px 5px 5px",
                    borderColor: state.isFocused ? `#2B2B2B` : `#656565`,
                    borderWidth: 0.5,
                    boxShadow: state.isFocused ? `0 0 0 1px #2B2B2B` : `0`,
                    font: state.isFocused ? 'normal normal normal 10px/12px InterBold' : 'normal normal normal 12px/12px InterRegular'
                }),
                placeholder: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? "white" : "#2B2B2B",
                })
            }}
            hideSelectedOptions={false}
            isClearable
            isSearchable
            isMulti
            onChange={handleChange}
            components={{ValueContainer: ValueComponent, Option: OptionComponent, SelectContainer: SelectComponent, Menu: MenuComponent}}
            value={valueSelect}
        />
    )
}

export default SelectObject;