import React from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faXmark } from '@fortawesome/free-solid-svg-icons';

library.add(faXmark);

const InputGroupText = styled(InputGroup.Text) `
    background-color: white;
    border-color: #656565;
    border-width: 0.5px;
    border-radius: 5px;
    cursor: pointer;
    border-left: 0px;

    & > svg {
        height: 12px;
    }

    & > svg > path {
        fill: #898989;
    }
`

const Xmark: React.FC<any> = ({onClick}) => {
    return (
        <InputGroupText id="basic-addon2" onClick={onClick}><FontAwesomeIcon icon={'xmark'}/></InputGroupText>
    )
}


const InputGroupComponent = styled(InputGroup) `
    border-radius: 5px;
    width: 250px;

    // &.open

    &.open-list > span:first-child {
        border-bottom-left-radius: 0;
    }

    &.open-list > span:last-child {
        border-bottom-right-radius: 0;
    }
`

const FormControl = styled(Form.Control)`
    border-color: #656565 !important;
    border-width: 0.5px !important;
    border-left: none;
    border-right: none;
    border-radius: 5px;
    height: 30px;
    font: normal normal normal 12px/12px InterRegular;

    &.form-control:focus {
        box-shadow: none !important;
    }

    &::placeholder {
        font: normal normal normal 12px/12px InterRegular;
    }
`

type PropsInput = {
    Icon: React.FC;
    open?: boolean;
    name: string;
    type: string;
    placeholder: string;
    handleOnChange: any;
    value: string | number | string[] | undefined;
    required: boolean | undefined;
}

const Input: React.FC<PropsInput> = ({
    Icon, open, name, type, placeholder, handleOnChange, value, required
}) => {

    const handle = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        handleOnChange({
            name: event.target.name,
            value: event.currentTarget.value
        });
    }

    const handleClickReset = (event: any) => {
        event.preventDefault();
        handleOnChange({
            name: 'search',
            value: ''
        });
    }

    return (
        <InputGroupComponent className={open ? 'open-list' : 'close-list'}>
            <Icon />
            <FormControl name={name} type={type} placeholder={placeholder} onChange={handle} value={value} required={required}/>
            <Xmark onClick={handleClickReset} />
        </InputGroupComponent>
    )
}

export default Input;