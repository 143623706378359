import axios from 'axios'
import { HOST_LOCAL, HEADER_JSON } from '../Constant/Host'

export const FirstConnection = async (data: {
        password: string,
        repeatPassword: string
    }, accessKey: string
) => {
    const loginData = await axios.post(HOST_LOCAL + `/first_connect/${accessKey}`, {
        password: data.password,
        repeat_password: data.repeatPassword
    }, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return loginData;
}

export const PasswordForgetRequest = async (email: string) => {
    const loginData = await axios.post(HOST_LOCAL + '/users/password/forgotten', email, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return loginData;
}

export const LoginRequest = async (login: {email: string, password: string, rememberMe: boolean}) => {
    const loginData = await axios.post(HOST_LOCAL + '/login', login, {
        headers: {
            ...HEADER_JSON
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return loginData;
}

export const RefreshRequest = async () => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const refresh_token = localStorage.getItem('refresh_token') || undefined;
    const refreshData = await axios.post(HOST_LOCAL + '/refresh', {
        refresh_token: refresh_token,
    }, {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return refreshData;
}

export const AccountRequest = async () => {
    const access_token = localStorage.getItem('access_token') || undefined;
    const refreshData = await axios.get(HOST_LOCAL + '/account', {
        headers: {
            ...HEADER_JSON,
            Authorization: `Bearer ${access_token}`
        }
    }).then((rep) => {
        const { status, data } = rep;
        return { statusRequest: status, dataRequest: data };
    }).catch((err) => {
        if (err.response !== undefined) {
            const { status, data } = err.response;
            const error: any = { status, data }
            throw error;
        } else {
            const {statusCode, message} = err;
            const error: any = { statusCode, message };
            throw error;
        }
    });
    return refreshData;
}
