import React from 'react';
import styled from 'styled-components';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

library.add(faMagnifyingGlass);

const InputGroupText = styled(InputGroup.Text) `
    background-color: white;
    border-width: 0.5px;
    border-color: #656565;
    padding-right: 0;
    border-right: none;
    border-radius: 5px;

    & > svg {
        height: 12px;
    }

    & > svg > path {
        fill: #898989;
    }
`

const Icon: React.FC<{open?: boolean}> = () => {
    return (
        <InputGroupText id="basic-addon1"><FontAwesomeIcon icon={'magnifying-glass'}/></InputGroupText>
    )
}

export default Icon;
